import compact from "lodash/compact";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import {
  getMhcLocationStatsWithDateSeries,
  getMhcLocationStatsWithoutDateSeries
} from "graphqlApi/legacy/mhcClient";

import {
  configKeys,
  IdentifierConfig,
  StatIdConfig,
  TopicDashboardSectionConfig,
  TopicDashboardSubsectionTypeConfig
} from "../elementHelpers/dashboard/types";
import { MhcAttributionFragment, MhcTimeSeriesGranularityEnum } from "graphqlApi/types";

import { log, logError, logInfo } from "common/util/consoleHelpers";
import { getStratificationIds } from "common/util/statIdentifierStratifications";
import { mergeConfigsByIdentifier } from "modules/Topics/util/fetchingFunctions/configurationMerger";

import { configString, getIdentifiersOfStatIdConfigs } from "./fetchSectionData";
import {
  LoadedLocationStatDictionary,
  LoadedLocationStatDictionaryByLocation,
  LoadedStat,
  LoadedStatDictionary
} from "./fetchStatsForAllSections";

/**
 * Get unique attributions from loaded stats dictionary
 *
 * @param loadedStatDictionary - dictionary of loaded stats
 *
 * @returns array of unique attributions from loaded stats
 *
 * @see LoadedStatDictionary
 * @see LoadedStat
 */
export const getAttributionsFromLoadedStats = (
  loadedStatDictionary: LoadedStatDictionary
): MhcAttributionFragment[] =>
  uniqBy(
    (Object.values(loadedStatDictionary) as LoadedStat[]).flatMap(
      (s: LoadedStat) => s.statIdentifier?.attributions ?? []
    ),
    "id"
  );

type FetchStatParams = {
  locationId: string;
  includeLocationObj?: boolean;
  statId: string;
  granularity: MhcTimeSeriesGranularityEnum | null;
  needStat: boolean;
  needSeries: boolean;
  startsOn?: string;
  endsOn?: string;
};

/**
 * Fetch stat from statConfig options
 *
 * @param {FetchStatParams} params
 * @param params.locationId - locationId to fetch stats
 * @param params.includeLocationObj - include location object in returned object
 * @param params.statId - stat identifier to fetch
 * @param params.granularity - granularity to fetch
 * @param params.needStat - include stat value in returned object
 * @param params.needSeries - include time series in returned object
 * @param params.startsOn - Start date for time series
 * @param params.endsOn - End date for time series
 *
 * @returns {LoadedStat} - loaded stat object as LoadedStat
 *
 * @see LoadedStat
 * @see FetchStatParams
 *
 */
export const fetchStat = async ({
  locationId,
  statId,
  granularity,
  needStat,
  needSeries,
  startsOn,
  endsOn
}: FetchStatParams): Promise<LoadedStat> => {
  const baseArgs = { locationId, granularity, startsOn, endsOn };
  const [stat, statWithTimeSeries] = await Promise.all([
    needStat && !needSeries
      ? (
          await getMhcLocationStatsWithoutDateSeries({ ...baseArgs, identifiers: [statId] })
        )?.[0]
      : {},
    needSeries
      ? (
          await getMhcLocationStatsWithDateSeries({ ...baseArgs, identifiers: [statId] })
        )?.[0]
      : {}
  ]);
  const data = { ...stat, ...statWithTimeSeries } as LoadedStat;
  return data;
};

export const processStatIdConfig = async ({
  statIdConfig,
  locationId,
  locationStatArgs
}: {
  statIdConfig: StatIdConfig;
  locationId: string;
  locationStatArgs?: Pick<IdentifierConfig, "startsOn" | "endsOn">;
}): Promise<LoadedStat & { _id: string }> => {
  let id = "";
  let granularity: MhcTimeSeriesGranularityEnum | null = null;
  let needStat = true;
  let needSeries = true;
  let fetchStratifications = false;
  let locId = locationId;
  if (typeof statIdConfig === "object") {
    id = statIdConfig.identifier;
    statIdConfig.granularity && (granularity = statIdConfig.granularity);
    needStat = statIdConfig.needStat ?? true;
    needSeries = statIdConfig.needSeries ?? true;
    if (statIdConfig.forLocationId) {
      locId = statIdConfig.forLocationId;
    }
    fetchStratifications = statIdConfig.needStratifications ?? false;
  } else {
    id = statIdConfig;
  }

  const baseQueryOptions = {
    locationId: locId,
    granularity,
    needStat,
    needSeries,
    startsOn:
      (statIdConfig as IdentifierConfig).startsOn ?? locationStatArgs?.startsOn ?? undefined,
    endsOn: (statIdConfig as IdentifierConfig).endsOn ?? locationStatArgs?.endsOn ?? undefined
  };

  const fetchedStat = await fetchStat({ ...baseQueryOptions, statId: id });

  let loadedStratifications;
  const stratIdentifiers = getStratificationIds(fetchedStat.statIdentifier);
  if (
    fetchStratifications &&
    fetchedStat?.statIdentifier &&
    stratIdentifiers &&
    stratIdentifiers.length > 0
  ) {
    const { locationId, startsOn, endsOn, granularity } = baseQueryOptions;
    loadedStratifications = await getMhcLocationStatsWithDateSeries({
      endsOn,
      granularity,
      identifiers: stratIdentifiers,
      locationId,
      startsOn
    });
  }
  loadedStratifications && (fetchedStat.stratifications = loadedStratifications as LoadedStat[]);
  const _id = fetchedStat.statIdentifier?.id ?? `UNKNOWN`;
  return {
    ...fetchedStat,
    _id: _id
  };
};

export type CreateLoadedStatDictionary = {
  /** Slug or id of location  */
  locationId: string;
  /** Objects with location stat and/or identifier loading options  */
  identifierConfigs: StatIdConfig[];
  /** optional loading options (startsOn, endsOn) to use if not given through identifierConfigs   */
  locationStatArgs?: Pick<IdentifierConfig, "startsOn" | "endsOn">;
};

const logConfigDetails = (
  identifierConfigs: StatIdConfig[],
  locationId: string,
  locationStatArgs: CreateLoadedStatDictionary["locationStatArgs"]
) => {
  log("\n");
  logInfo(`---------------------------------------------------------------------`);
  logInfo(`Loading stats for ${identifierConfigs.length} configs`);
  logInfo(`---------------------------------------------------------------------`);
  identifierConfigs.forEach((c) => {
    const isAConfig = typeof c === "object";
    logInfo(isAConfig ? c.identifier : c);
    let { endsOn, startsOn } = locationStatArgs ?? {};
    isAConfig && c.endsOn && (endsOn = c.endsOn);
    isAConfig && c.startsOn && (startsOn = c.startsOn);
    const logLines = compact([
      `location: ${isAConfig ? c.forLocationId ?? locationId : locationId}`,
      isAConfig && `loading stat: ${c.needStat?.toString() ?? "false"}`,
      isAConfig && `loading series: ${c.needSeries?.toString() ?? "false"}`,
      isAConfig && `loading stratifications: ${c.needStratifications?.toString() ?? "false"}`,
      startsOn && endsOn && `date range: ${startsOn ?? "NOT_SET"} - ${endsOn ?? "NOT_SET"}`,
      isAConfig && c.granularity && `granularity: ${c.granularity}`
    ]);
    logLines.forEach((line) => logInfo(` * ${line}`));
  });
  logInfo(`---------------------------------------------------------------------`);
  log("\n");
};

/**
 * Fetch stats from configs and return a dictionary of loaded stats
 *
 * @param params
 * @param params.locationId - locationId to fetch stats for (unless overridden by statIdConfig.forLocationId)
 * @param params.identifierConfigs - array of statIds or statIdConfigs
 * @param params.locationStatArgs - optional loading options (startsOn, endsOn) to use if not given through identifierConfigs
 *
 * @returns Dictionary of stats by statIdentifier.id and locationId
 *
 * @see StatIdConfig
 * @see LoadedLocationStatDictionaryByLocation
 * @see fetchedStat
 */
export const createLoadedStatDictionary = async ({
  locationId,
  identifierConfigs: _identifierConfigs,
  locationStatArgs
}: CreateLoadedStatDictionary): Promise<LoadedLocationStatDictionaryByLocation> => {
  const dictionary: LoadedLocationStatDictionaryByLocation = {};
  let identifierConfigs = _identifierConfigs;
  /**
   * This should fix most possible errors, but still it's too complex
   * trying to ensure that we are joining all
   * configurations without affecting the layout. Initially, all of the topic page data fetching logic
   * was crafted this way, assuming that multiple data sections on the same page could fetch the same stat with
   * similar configurations. Still, the maps use a different query than KPIs and charts.
   *
   * Additionally, when refactoring into the app router we could delegate the data loading to each component
   * instead of loading all of the data at the same time. Because of this, we could probably delete the merging
   * logic after we wrap up the topics refactor into the app router.
   */
  identifierConfigs = identifierConfigs.map((config) =>
    typeof config === "string"
      ? config
      : {
          ...config,
          identifier: configString(config.identifier)
        }
  );
  if (identifierConfigs.every((c) => typeof c === "object")) {
    identifierConfigs = mergeConfigsByIdentifier(
      identifierConfigs as IdentifierConfig[],
      locationId
    );
  } else if (identifierConfigs.every((c) => typeof c === "string")) {
    identifierConfigs = uniq(identifierConfigs);
  }
  try {
    process.env.DATA_LOADING_LOGGING_ENABLED &&
      logConfigDetails(identifierConfigs, locationId, locationStatArgs);
    await Promise.all(
      identifierConfigs.map(async (statIdConfig) => {
        const { _id, ...fetchedStat } = await processStatIdConfig({
          statIdConfig,
          locationId,
          locationStatArgs
        });
        dictionary[_id] ||= {};
        (dictionary[_id] as LoadedLocationStatDictionary)[fetchedStat?.location?.id ?? locationId] =
          fetchedStat;
      })
    );
  } catch (e: unknown) {
    logError(e);
    // throw new Error(`Error loading stats: ${e as string}`);
  }
  return dictionary;
};

export const getAllStatIdsFromPageConfig = (
  config: TopicDashboardSectionConfig[] | null
): string[] => {
  if (!config) return [];
  return uniq(
    config.flatMap((c) => {
      const { subSections } = c;
      if (!subSections) return [];
      return subSections.flatMap((subSection) => {
        const configs = pick(subSection, configKeys);
        if (isEmpty(configs)) return [];
        return Object.values(configs).flatMap((subConfig) => {
          if (isArray(subConfig)) {
            return subConfig.flatMap(({ statIdConfigs = [] }) =>
              getIdentifiersOfStatIdConfigs(statIdConfigs)
            );
          }
          return getIdentifiersOfStatIdConfigs(
            (subConfig as TopicDashboardSubsectionTypeConfig)?.statIdConfigs ?? []
          );
        });
      });
    })
  );
};
